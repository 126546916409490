import {ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {animate, style, transition, trigger} from "@angular/animations";
import {Observable, Subscription} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {DataService} from "../../services/data.service";
import {ArtWalksQuery} from "../../graphql/gql.overrides";

@Component({
  selector: 'app-walk',
  templateUrl: './walk.component.html',
  styleUrls: ['./walk.component.scss'],
  animations: [
    trigger('anim', [
      transition(':enter', [
        style({transform: 'translateY(100%)', display: 'block'}),
        animate('0.15s linear', style({transform: 'translateY(0%)'}))
      ]) ,
      transition(':leave', [
        style({transform: 'translateY(0%)', display: 'block', 'max-height': '100vh'}),
        animate('0.15s linear', style({transform: 'translateY(100%)'}))
      ])
    ])
  ]
})
export class WalkComponent implements OnInit, OnDestroy {
  @HostBinding('@anim')

  public $subs: Subscription[] = []
  public $metaContent: Observable<ArtWalksQuery['entries'][0]>;
  public routeData: google.maps.DirectionsLeg;
  public currentWalk: string = null;

  constructor(
    public route: ActivatedRoute,
    public dataService: DataService,
    public cdRef: ChangeDetectorRef,
    public router: Router,
  ) { }

  ngOnInit(): void {
    this.$subs.push(
      this.route.params.subscribe(params => {

        this.currentWalk = params.slug;
        this.$metaContent = this.dataService.findWalk(params.slug);
        this.routeData = null;

        this.$subs.push(
          this.$metaContent.subscribe(data => {

            this.dataService.map.setFocusedMarkers(data.artworks.map((e)=>{return e.slug}));

            setTimeout(()=>{
              // @ts-ignore
              this.dataService.map.centreMap(data.artworks[0].position, 15);
            },200)

            this.$subs.push(
              // @ts-ignore
              this.dataService.map.renderRoute(this.dataService.map.gpsPosition, data.artworks[data.artworks.length-1].position, data.artworks.map((e)=>{return e.position}))
                .subscribe(data => {
                  this.routeData = data.routes[0].legs[0]
                  this.cdRef.detectChanges();
                })
            )

          })
        )

      })
    )
  }

  startWalk(slug): void {
    this.router.navigate(['/content/'+slug], {queryParams: {w: this.currentWalk} ,queryParamsHandling: 'merge', preserveFragment: true});
  }

  ngOnDestroy(): void {
    this.dataService.map.stopRendering();
    this.$subs.forEach(sub => {
      sub.unsubscribe();
    })
  }

}
