<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <h1 class="mb-5">FAQ</h1>
        <p>
          The Alice Springs Online Public Art Map displays the diverse and vibrant range of public art that
          can be found in Alice Springs, and is for residents and visitors alike to enjoy. This map has been
          developed by Alice Springs Town Council, and supported by the Northern Territory Government.
        </p>
        <h3>
          Why was this map created?
        </h3>
        <p>
          The map was created after consultation with the Alice Springs community for Alice Springs Town
          Council’s Arts and Cultural Plan, which identified developing an online public art map as a key
          strategic objective.
        </p>
        <h3>Accessibility</h3>
        <p>
          For accessibility information, please refer to Alice Springs Town Council’s
          <a href="https://alicesprings.nt.gov.au/about-alice-springs/living-in-alice-springs/accessibility">Mobility Map</a>,
          which lists key information such as ramps, accessible parking and toilets, and disability services. Some
          of the CBD public art work listings on the online public art map also contain an audio description
          for people with visual impairments.
        </p>
        <h3>How do I request an artwork to be added? </h3>
        <p>
          If you have found a public artwork that isn’t currently listed on the map, please email
          <a href="mailto:astc@astc.nt.gov.au" >astc@astc.nt.gov.au</a> with a photo of the work and location,
          and any other details you may have, such as artist name and artist contact details. Council
          Officers will review your request, and confirm whether the artwork will be added.
        </p>
        <h3>How do I report information on the map that I believe to be incorrect?</h3>
        <p>
          Please email <a href="mailto:astc@astc.nt.gov.au" >astc@astc.nt.gov.au</a> with the information, and
          a Council Officer will review your feedback and respond to your enquiry.
        </p>
      </div>
    </div>
  </div>
</div>
