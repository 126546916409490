<div class="content quarter">
  <div class="container-fluid">
    <div class="row">
      <div class="col" *ngIf="$metaContent">

        <ng-container *ngIf="($metaContent | async) as metaData">
          <div class="meta-data">

            <div class="image">
              <img [src]="metaData.featureImage[0].medium">
            </div>

            <div class="text">

              <div class="buttons">
                <a (click)="startDirections(metaData)" class="btn btn-primary" [class.disabled]="!routeData">Directions</a>
                <a [routerLink]="'/'" preserveFragment="true" [queryParamsHandling]="'preserve'" class="btn btn-primary">
                  <i class="fa fa-fw fa-close"></i>
                </a>
              </div>

              <h1>{{ metaData.title }}</h1>
              <p class="distance" *ngIf="routeData">{{routeData.distance.text}}, {{routeData.duration.text}}</p>
              <p class="distance" *ngIf="!routeData">Loading...</p>

              <div class="meta">
                <h6>
                  <ng-container *ngFor="let artist of metaData.artist;  let last = last;">
                    {{artist.artist}}<ng-container *ngIf="!last">, </ng-container>
                  </ng-container>
                </h6>
                <h6>
                  {{metaData.year}}
                </h6>
                <h6>
                  {{metaData.medium}}
                </h6>
              </div>

              <hr>
              <div class="collection" *ngIf="this.$navData | async as navData">
                <h6>{{navData.name}}</h6>
                <p>{{navData.pos}} of {{navData.total}}</p>

                <div class="d-flex nav-buttons">
                  <div class="back">
                    <div class="btn btn-secondary" [class.disabled]="!navData.prev"
                         preserveFragment="true" [queryParamsHandling]="'preserve'"
                         [routerLink]="navData.prev ? '/content/'+navData.prev.slug : null">Back</div>
                    <p *ngIf="navData.prev">{{navData.prev.title}}</p>
                  </div>
                  <div class="next">
                    <p *ngIf="navData.next">{{navData.next.title}}</p>
                    <div class="btn btn-secondary" [class.disabled]="!navData.next"
                         preserveFragment="true" [queryParamsHandling]="'preserve'"
                         [routerLink]="navData.next ? '/content/'+navData.next.slug : null">Next</div>
                  </div>
                </div>
              </div>

            </div>

          </div>

          <hr>

          <div [innerHTML]="metaData.description"></div>

        </ng-container>

        <div class="d-flex" *ngIf="!readMore">
          <div class="btn btn-outline-secondary my-4 ml-auto mr-auto" (click)="readMore = !readMore">More Info</div>
        </div>

        <iframe *ngIf="contentUrl && readMore" [src]='contentUrl' appIframeAutoHeight></iframe>

      </div>
    </div>
  </div>
</div>
