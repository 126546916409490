<app-header #header id="header"></app-header>

<app-map #map></app-map>

<app-menu #menu></app-menu>

<main>
  <div>
    <router-outlet #appOutlet="outlet"></router-outlet>
  </div>
</main>

<app-footer #footer *ngIf="!this.map.isDirecting() && this.router.url.split('?')[0].split('#')[0] == '/'"></app-footer>
