<div class="map-menu" [class.show]="show">
  <div class="content pt-4">

    <ng-container *ngIf="dataService.$getList | async as list">

      <a class="btn btn-sm btn-primary mr-3" style="float: right"
      *ngIf="list.walk" (click)="cancelWalk()">Cancel Walk</a>

      <ng-container *ngIf="!list.walk">
        <a class="btn btn-sm btn-primary mr-3" style="float: right"
           *ngIf="showWalks" (click)="toggleWalks()">All Artworks</a>
        <a class="btn btn-sm btn-primary mr-3" style="float: right"
           *ngIf="!showWalks" (click)="toggleWalks()">Art Trails</a>
      </ng-container>

      <ng-container *ngIf="list.walk || !showWalks">
        <h4 class="mb-5 mx-2">{{list.name}}</h4>

        <a class="menu-item" *ngFor="let item of list.artworks" (click)="getMenuItem('content/'+item.slug)"
           [routerLink]="'content/'+item.slug" preserveFragment="true" [queryParamsHandling]="'preserve'" routerLinkActive="active">
          <img [src]="item.featureImage[0].thumbnail">
          <div class="main">
            <h6>{{ item.title }}</h6>
<!--            <p>-->
<!--              {{item.medium}}-->
<!--              <ng-container *ngIf="item.artist.length === 1"> by {{item.artist[0].artist}}</ng-container>-->
<!--              <ng-container *ngIf="item.artist.length > 1"> by multiple artists</ng-container>-->
<!--              <ng-container *ngIf="item.year"> ({{item.year}})</ng-container>-->
<!--            </p>-->
          </div>
        </a>
      </ng-container>

      <ng-container *ngIf="!list.walk && showWalks">
        <h4 class="mb-5 mx-2">Art Trails</h4>

        <ng-container *ngIf="dataService.$artWalks | async as walks">
          <a class="menu-item" *ngFor="let walk of walks" (click)="getMenuItem('walk/'+walk.slug)"
             [routerLink]="'walk/'+walk.slug" preserveFragment="true" [queryParamsHandling]="'preserve'" routerLinkActive="active">
            <img [src]="walk.artworks[0].featureImage[0].thumbnail">
            <h6>{{walk.title}}</h6>
            <p>{{walk.artworks.length}} stops</p>
          </a>
        </ng-container>
      </ng-container>

    </ng-container>

  </div>
</div>

<div class="dim-overlay" *ngIf="show" (click)="toggle(false)"></div>
