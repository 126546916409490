<google-map [height]="'100%'" [width]="'100%'" [center]="centre" [zoom]="defaultZoom"
(mapDragstart)="touch()">
  <ng-container *ngFor="let marker of dataService.$publicArtworks | async">
    <map-marker
      [className]="'art-marker'"
      [position]="marker.position"
      [title]="marker.title"
      [options]="{'icon': {'url': marker.featureImage[0].icon, scaledSize: {width: 40, height: 53}}, 'opacity': (this.focusedMarkers.length !== 0 && !this.focusedMarkers.includes(marker.slug) ? 0.1 : 1) }"
      (mapClick)="markerClick(marker.slug)"
      [clickable]="!(this.focusedMarkers.length !== 0 && !this.focusedMarkers.includes(marker.slug))"
    ></map-marker>
  </ng-container>
</google-map>

<div class="directions" *ngIf="renderInterval">
  <div class="icon">
    <img [src]="destinationMetaData.featureImage[0].small">
  </div>

  <div class="text">
    <div class="left">
      <div>
        <h4 *ngIf="directionsData && directionsData.steps.length && destinationDistance >= 50" [innerHTML]="directionsData.steps[0].instructions"></h4>
        <h4 *ngIf="!directionsData">Loading...</h4>
        <h4 *ngIf="directionsData && destinationDistance < 50">Arrived at destination</h4>
        <p *ngIf="directionsData && destinationDistance >= 50"><b>{{destinationMetaData.title}}</b> <span> ({{directionsData.distance.text}}, {{directionsData.duration.text}})</span></p>
        <p *ngIf="!directionsData || directionsData && destinationDistance < 50">{{destinationMetaData.title}}</p>
      </div>
    </div>
    <div class="right">
      <a *ngIf="!directionsData || destinationDistance >= 50" (click)="recentre()" class="btn btn-outline-secondary">Recentre</a>
      <ng-container *ngIf="$destinationNav | async as destinationNav">
        <a *ngIf="directionsData && destinationDistance < 50 && destinationNav.next" (click)="markerClick(destinationNav.next.slug)" class="btn btn-outline-secondary">Next</a>
      </ng-container>
      <a (click)="markerClick(destinationMetaData.slug)" class="btn btn-outline-secondary">
        <ng-container *ngIf="directionsData && destinationDistance < 50">View</ng-container>
        <i class="fa fa-fw fa-close" *ngIf="!(directionsData && destinationDistance < 50)"></i>
      </a>
    </div>
  </div>
</div>
