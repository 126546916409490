import {AfterViewInit, Directive, ElementRef, OnDestroy, OnInit, Renderer2} from '@angular/core';
import * as resizer from 'iframe-resizer';

@Directive({
  selector: '[appIframeAutoHeight]'
})
export class IframeAutoHeightDirective implements AfterViewInit, OnDestroy{

  private component;
  private interval;

  constructor(public element: ElementRef) {
  }

  ngAfterViewInit() {
    this.component = resizer.iframeResizer({
      checkOrigin: false,
      heightCalculationMethod: 'documentElementOffset',
      log: false
    }, this.element.nativeElement);

    this.interval = setInterval(() => {
      let data = {
        source: 'app',
        height: window.innerHeight - document.querySelector('#header').scrollHeight
      };
      this.element.nativeElement.contentWindow.postMessage(JSON.stringify(data),'*')
    }, 500)
  }

  ngOnDestroy(): void {
    if (this.component && this.component.iFrameResizer) {
      this.component.iFrameResizer.close();
    }
    clearInterval(this.interval);
  }
}
