<div class="content half">
  <div class="container-fluid">
    <div class="row">
      <div class="col" *ngIf="$metaContent | async as walk">

        <div class="buttons">
          <a (click)="startWalk(walk.artworks[0].slug)" class="btn btn-primary">
            Start
          </a>
          <a [routerLink]="'/'" preserveFragment="true" [queryParamsHandling]="'preserve'" class="btn btn-primary ml-2">
            <i class="fa fa-fw fa-close"></i>
          </a>
        </div>

        <h2 class="mt-3 mb-0">{{walk.title}}</h2>
        <p class="sub-header">
          {{walk.artworks.length}} Stops
          <span *ngIf="routeData"> ({{routeData.distance.text}}, {{routeData.duration.text}})</span>
        </p>

        <div [innerHTML]="walk.description"></div>

        <hr class="my-5">

        <h5 class="mb-4">Artworks on this trail</h5>

        <div class="artworks">
          <div class="artwork" *ngFor="let artwork of walk.artworks" (click)="this.dataService.menu.getMenuItem('content/'+artwork.slug)">
            <div>
              <img [src]="artwork.featureImage[0].small">
            </div>
            <div class="text">
              <h5>{{artwork.title}}</h5>
              <p class="meta">
                {{artwork.medium}}
<!--                <ng-container *ngIf="artwork.artist.length === 1"> by {{artwork.artist[0].artist}}</ng-container>-->
<!--                <ng-container *ngIf="artwork.artist.length > 1"> by multiple artists</ng-container>-->
<!--                <ng-container *ngIf="artwork.year"> ({{artwork.year}})</ng-container>-->
              </p>
              <div [innerHTML]="artwork.description" style="font-size: 14px"></div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
