import {Component, HostBinding, OnInit} from '@angular/core';
import {DataService} from "../../services/data.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

  public show: boolean = false;
  public showWalks: boolean = false;

  constructor(public router: Router, public dataService: DataService) { }

  public getMenuItem(url: string): void {
    this.dataService.map.stopRendering()
    this.toggle(false);
    this.router.navigate([url],{queryParamsHandling: 'preserve', preserveFragment: true});
  }

  public toggle(state: boolean = null): void {
    this.show = state !== null ? state : !this.show;
    if (this.show) this.dataService.refresh();
  }
  public toggleWalks(state: boolean = null): void {
    this.showWalks = state !== null ? state : !this.showWalks;
  }

  public cancelWalk() {
    console.log(this.router.url.split('?')[0])
    this.router.navigate([this.router.url.split('?')[0]],{queryParams: {w: null}, queryParamsHandling: 'merge', preserveFragment: true});
  }

}
