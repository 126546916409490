import {Injectable, NgZone} from '@angular/core';
import {combineLatest, Observable} from "rxjs";
import {
  ArtWalksGQL,
  PublicArtworksGQL,
} from "../../generated/graphql";
import {map, take} from "rxjs/operators";
import {MapComponent} from "../components/map/map.component";
import {MenuComponent} from "../components/menu/menu.component";
import {ArtWalksQuery, PublicArtworksQuery} from "../graphql/gql.overrides";
import {ActivatedRoute} from "@angular/router";
import {ToastrService} from "ngx-toastr";

export type ListNav = {
  prev?: PublicArtworksQuery['entries'][0],
  next?: PublicArtworksQuery['entries'][0],
  pos: number,
  total: number,
  name: string
}

export type ListData = {
  name: string,
  artworks: PublicArtworksQuery['entries'],
  walk: boolean
}

@Injectable({
  providedIn: 'root'
})
export class DataService {
  public map: MapComponent;
  public menu: MenuComponent;

  public $artWalks: Observable<ArtWalksQuery['entries']>
  public $publicArtworks: Observable<PublicArtworksQuery['entries']>;
  public $getList: Observable<ListData>;

  constructor(
    private publicArtworksGQL: PublicArtworksGQL,
    private artWalksGQL: ArtWalksGQL,
    private route: ActivatedRoute,
    private zone: NgZone,
    private toastr: ToastrService,
  ) {
    // @ts-ignore
    this.$publicArtworks = this.publicArtworksGQL.watch().valueChanges.pipe(map(result => {
      return result.data.entries
    }));
    // @ts-ignore
    this.$artWalks = this.artWalksGQL.watch().valueChanges.pipe(map(result => {
      return result.data.entries
    }));

    this.$getList = combineLatest(
      this.route.queryParams,
      this.$publicArtworks,
      this.$artWalks
    ).pipe(map(([params, artworks, walks]) => {
      let w = walks.find(x => x.slug === params['w']);
      let j: ListData;
      if (!w) {
        j = {
          name: "All Artworks",
          artworks: artworks,
          walk: false
        }
      } else {
        j = {
          name: w.title,
          // @ts-ignore
          artworks: w.artworks,
          walk: true
        }
      }
      return j;
    }))

    this.$getList.subscribe(() => {
    }, error => {
      this.toastr.error('Tap here to refresh, or try back later.', 'Network data error', {
        disableTimeOut: true,
      }).onTap
        .pipe(take(1))
        .subscribe(() => window.location.reload());
    })

  }

  public refresh (delay: number = 0): void {
    if (delay <= 0) {
      console.log('refresh data');
      // this.publicArtworksGQL.watch().resetLastResults();
      this.publicArtworksGQL.watch().getCurrentResult();
      // this.artWalksGQL.watch().resetLastResults();
      this.artWalksGQL.watch().refetch();
      return;
    }
    console.log('Waiting '+delay+'ms to refresh');
    setTimeout(()=>{
      this.refresh();
    }, delay)

  }

  public findArtwork (slug: string): Observable<PublicArtworksQuery['entries'][0]> {
    return new Observable(subscriber => {
      this.$publicArtworks.subscribe(result => {
        subscriber.next(result.find(x => x.slug === slug));
        subscriber.complete();
      }, error => {
        subscriber.error(error)
      })
    });
  }

  public findWalk (slug: string): Observable<ArtWalksQuery['entries'][0]> {
    return new Observable(subscriber => {
      this.$artWalks.subscribe(result => {
        subscriber.next(result.find(x => x.slug === slug));
        subscriber.complete();
      }, error => {
        subscriber.error(error)
      })
    });
  }

  public getListNav (slug: string): Observable<ListNav> {
    return new Observable(subscriber => {
      this.$getList.subscribe((data) => {

        let i = data.artworks.map((e)=>{return e.slug}).indexOf(slug);
        if (i === -1) return null;

        let l = data.artworks.length;

        subscriber.next( {
          prev: i > 0 ? data.artworks[i-1] : null,
          next: i < l ? data.artworks[i+1] : null,
          pos: i+1,
          total: l,
          name: data.name
        })
        subscriber.complete();
      }, error => {
        subscriber.error(error)
      })
    });
  }

}
